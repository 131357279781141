.hero {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: $baseGrey3;

  // Case (detail) page: Header styling exception
  &.solid {
    min-height: 220px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    }

    .hero-container {
      position: relative;

      .hero-inner {
        position: relative;
        padding: 0 0 20px;
        background-color: transparent;

        #go-back {
          position: relative;
          margin: 0 0 80px 16px;
        }
      }
    }
  }

  .title-line {
    margin-top: 20px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, .45);

    @include breakpoint($large-up) {
      position: relative;
      top: 0;
      margin-top: 0;
    }
  }

  // hero image toggle (mobile/desktop)
  .hero-img:not(.desktop-and-mobile) {
    display: none;

    @include breakpoint($medium-up) {
      display: block;
    }
  }

  @include breakpoint($medium-up) {
    .hero-img-mobile {
      display: none;
    }
  }
}

.go-back {
  display: inline-block;
  width: 100%;

  @include breakpoint($large-up) {
    display: inline-block;
  }
}

#go-back {
  position: absolute;
  margin-left: 16px;
  padding-top: 4px;
  border-bottom: 1px solid transparent;
  font-family: $fontTypeBase;
  font-size: 14px;
  line-height: 18px;
  text-shadow: none;

  @include breakpoint($large-up) {
    position: relative;
    display: inline-block;
    float: left;
    margin: 0 0 80px 16px;
  }

  &:hover {
    text-decoration: none;
    border-bottom: 1px solid white;

  }
}

.hero-container {
  padding: 0 20px;

  @include breakpoint($xlarge-up) {
    width: 1165px;
    margin: 0 auto;
    padding: 0;
  }
}

.hero-img {
  position: relative;
  display: block;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include breakpoint($medium-up) {
    height: 300px;
  }

  @include breakpoint($large-up) {
    height: 450px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  }

  &.pictures-hero {
    display: block;
    width: 100%;
    height: 100%;
    background-position: right;
    background-size: auto;
    background-color: #000;
  }
}

.hero-img-mobile {
  position: relative;
  display: block;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hero-inner {
  width: 100%;
  background-color: $baseGrey3;
  padding-bottom: 19px;

  @include breakpoint($large-up) {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 75%;
    background-color: transparent;
  }
}

.hero-content {
  padding: 0;

  @include breakpoint($large-up) {
    display: inline-block;
    float: left;
    clear: both;
    min-width: 360px;
    padding: 20px 20px 0;
    background-color: rgba(255, 255, 255, .35);
  }

  .cta {
    display: inline-block;
    margin-bottom: 20px;
  }

  .btn {
    margin-bottom: 20px;
  }
}

.hero-text {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
  color: $baseLight;
  text-shadow: 0 2px 4px rgba(0, 0, 0, .45);
  @include font-smoothing(true);

  a {
    color: white;
  }
}
