.page-cases {
  .card {
    .card-img {
      height: 205px;
    }
  }
}
.case-item {
  padding-top: 20px;
  width: 100%;
}
.content > .case-item:first-child {
  padding-top: 0;
}
.case-item:not(:last-child):after {
  content: '';
  display: block;
  margin-top: 10px;
  width: 100%;
  height: 1px;
  background-color: $baseGrey;

  @include breakpoint($medium-up) {
    margin-top: 20px;
  }
}
.case-item-title {
  width: 100%;

  @include breakpoint($medium-up) {
    width: 35%;
    padding-right: 20px;
  }

  h3 {
    margin-top: 1.22em;
    font-size: 1.5rem;
  }
}
.case-item-text {
  width: 100%;
  margin: -1.75em 0 0.5em;

  @include breakpoint($medium-up) {
    width: 65%;
    margin: 0;
  }

  .grid-item {
    position: relative;

    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 3px 10px;
      background-color: rgba(0, 0, 0, 0.5);
      color: $baseLight;
    }

    $elements: 25;
    @for $i from 0 to $elements {
      &:nth-child(#{$i + 1})::after {
        content: 'Picture #{$i +  1}';
      }
    }
  }
}
.case-item-img {
  width: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
}
