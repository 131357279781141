.footer {
  width: 100%;
  background-color: $baseGrey;
}

.footer-item {
  width: 100%;

  &::after {
    content: '';
    display: block;
    margin-bottom: 20px;
    width: 100%;
    height: 1px;
    background-color: $borderGrey;
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }

  @include breakpoint($large-up) {
    width: calc(25% - 40px);

    &::after {
      display: none;
    }
  }

  input {
    margin-bottom: 18px;
  }
}

.disclaimer {
  padding: 10px 20px;
  width: 100%;
  background-color: $baseRed;
}

.disclaimer-inner {
  margin: 0 auto;
  width: 100%;
  max-width: 1150px;
}

.disclaimer-link {
  position: relative;
  top: 2px;
  line-height: 26px;
  color: $baseLight;
  text-decoration: none;
  @include font-smoothing(true);

  &:hover,
  &:active,
  &:focus,
  &.active {
    border-bottom: 4px solid #ffffff;
    padding-bottom: 8px;
  }
}

// Footer mobile styling
.footer-item-content {
  display: none;

  @include breakpoint($large-up) {
    display: block;
  }
}

.footer-item-header {
  background-image: url("/images/icons/plus-red.png");
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
  user-select: none;

  @include breakpoint($large-up) {
    background-image: none !important;
    cursor: default;
  }
}

.open {
  display: block;
}

.footer-line {
  outline: none;
  border: 0;
  width: 100%;
  height: 1px;
  background-color: $borderGrey;
  margin-bottom: 20px;

  @include breakpoint($large-up) {
    background-color: $borderGrey;
    margin-bottom: 40px;
    margin-top: 40px;
  }
}
