.search-item {
  transition: all .2s ease;

  &::before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: $baseGrey2
  }

  &:nth-last-of-type(2) {
    margin-bottom: 20px;

    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: $baseGrey2
    }
  }

  &:hover {
    background-color: $baseGrey;
  }
}

.search-link {
  text-decoration: none;
  color: $fontDark;
}

.item-content {
  padding: 20px;
}
