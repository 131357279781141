.page-pictures {
  .picture-categories {
    .card-img {
      height: 300px;
    }
  }

  .btn-card-bordered {
    max-width: none;
  }
}
.potw-gallery {
  .grid-item {
    position: relative;

    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 3px 10px;
      background-color: rgba(0, 0, 0, 0.5);
      color: $baseLight;
    }

    $elements: 25;
    @for $i from 0 to $elements {
      &:nth-child(#{$i + 1})::after {
        content: 'Picture #{$i +  1}';
      }
    }
  }
}
