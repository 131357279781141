// Breakpoints

$small: 400px;
$medium: 600px;
$large: 900px;
$xlarge: 1200px;
$xxlarge: 1600px;

$mainmenu: 1020px;

// Ranges starting form a breakpoint
$small-up: ( min:  $small );
$medium-up: ( min:  $medium );
$large-up: ( min: $large );
$xlarge-up: ( min:  $xlarge );
$xxlarge-up: ( min:  $xxlarge );

$mainmenu-up: ( min: $mainmenu );

// Ranges between breakpoints
$small-to-medium: ( min: $small, max: #{$medium - 1} );
$medium-to-large: ( min: $medium, max: #{$large - 1} );
$large-to-xxlarge: ( min: $large, max: #{$xxlarge - 1} );

// Ranges up to a breakpoint
$small-down: ( max: #{$small - 1} );
$medium-down: ( max: #{$medium - 1} );
$large-down: ( max: #{$large - 1} );

$mainmenu-down: ( max: #{$mainmenu - 1} );
