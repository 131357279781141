// Grid card element styles
.card {
  .ratio {
    @include breakpoint($large-up) {
      transition: transform .25s ease-out, z-index 0s ease .25s;

      &.zoomable {
        cursor: nesw-resize;

        &:not(.zoom):hover {
          transform: scale(1.02);
        }
      }

      &.zoom {
        transform: scale(2);
        position: relative;
        z-index: 2;
        transition: transform .25s ease-out;
      }
    }
  }
}
.card-img {
  width: 100%;
  display: block;
  margin-bottom: 28px;
}
.card-img img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-size: cover;
  background-position: center;
}
.card-content {
  //padding-top: 20px;

  & + .btn {
    margin: 20px 0;
  }
}
.card-content-fill {
  padding-top: 20px;
  padding-bottom: 20px;
}
.card-date {
  margin-top: 0.5em;
}
.card-text {
  margin-top: 0.5em;

  a {
    color: $baseRed;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  & + .events {
    margin-top: 20px;
  }
}
.bordered {
  padding: 20px;
  position: relative;
}
