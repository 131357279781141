.page-techniques {

  .grid {
    margin-bottom: 20px;
  }

  @include breakpoint($large-down) {
    .sidebar {
      order: 2;
    }

    .main {
      order: 1;
    }
  }

  .technique-categories {
    .card-img {
      height: 300px;
    }

    .btn-card-bordered {
      width: 100%;
    }
  }

  .referrals {
    background-color: $baseLight;
  }
}

.procedure-video {
  .card-content {
    padding: 0;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 20px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.steps {
  margin: 0 -20px;
  padding: 20px;
  background-color: $baseGrey;

  &.m-top > .grid:not(.m-bottom) .grid-item {
    margin-bottom: 0;
  }

  @include breakpoint($large-up) {
    margin: 0;
    padding: 20px;
  }

  .m-bottom {
    margin-bottom: -20px;
  }

  > .btn {
    margin-top: 20px;
  }
}

.procedure-step {
  background-color: $baseLight;
}