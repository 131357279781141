// Event block styling
// Used on Homepage

.events-grid {
  justify-content: space-between;
}

.event-item {
  position: relative;
  width: 100%;

  @include breakpoint($large-up) {
    width: calc(50% - 20px);
  }

  &::after {
    content: '';
    display: block;
    margin-top: 20px;
    width: 100%;
    height: 1px;
    background-color: $borderGrey;
  }

  &:last-child {
    margin-bottom: -40px;

    &:after {
      display: none;
    }
  }

  @include breakpoint($large-up) {
    &::after {
      position: absolute;
      top: 0;
      right: -10px;
      width: 1px;
      height: calc(100% - 40px);
    }

    &:nth-child(even) {
      &::after {
        display: none;
      }
    }
  }

  @include breakpoint($xlarge-up) {
    &::after {
      right: 0;
    }
  }
}

.event-label,
.event-title {
  margin-bottom: 0.5em;
  font-family: $fontTypeBaseBold;
}

.event-desc {
  max-width: 90%;
}
