// Font sizes
$sizeFontSmaller: 1.2rem;
$sizeFontSmall: 1.4rem;
$sizeFontTitleLarge: 2.8rem; // 3.6
$sizeFontTitleLarger: 3.2rem; // 4
$sizeFontSubTitle: 1.6rem;
$sizeFontHighlight: 2rem;

// Fonts
$fontTypeBase: 'MontserratRegular', sans-serif;
$fontTypeBaseBold: 'MontserratBold', sans-serif;
