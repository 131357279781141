// CSS grid columns
.g-1 {
  .grid-item {
    width: 100%;
  }
}

.g-2 {
  .grid-item {
    width: 100%;

    @include breakpoint($large-up) {
      width: calc(50% - 20px);
    }
  }
}

.g-3 {
  .grid-item {
    width: 100%;

    @include breakpoint($large-up) {
      width: calc(33.33% - 20px);
    }
    @include breakpoint($large-to-xxlarge) {
      &:nth-child(3n+1) {
        .ratio {
          transform-origin: left center;
        }
      }
      &:nth-child(3n+2) {
        .ratio {
          transform-origin: center;
        }
      }
      &:nth-child(3n+3) {
        .ratio {
          transform-origin: right center;
        }
      }
    }
  }
}

.g-4 {
  .grid-item {
    width: 100%;

    @include breakpoint($large-up) {
      width: calc(25% - 20px);
    }
  }
}

.grid-item.submit-case {
  //margin-bottom: 0;
}

.gap-wide {
}
