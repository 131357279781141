.pagination {
  width: 100%;
}

.button-holder {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  width: 150px;
}

#carousel-item-indicators {
  display: inline-block;
}

.next-carousel-item, .previous-carousel-item {
  width: 10px;
  height: 10px;
  background-image: url("../images/icons/arrow-red.png");
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all .2s ease;

  &:hover {
    opacity: .7;
  }
}

.next-carousel-item {
}

.previous-carousel-item {
  transform: rotate(180deg);

  &:hover {
  }
}

.indicator {
  margin: 0 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: $baseGrey3;
  border-radius: 5px;

  &.active {
    background: $baseRed;
  }
}
