// Header menu styling
.header-nav {
  padding: 5px 20px 5px 15px;

  @include breakpoint($mainmenu-up) {
    width: 100%;
    padding: 0 15px;
    justify-content: flex-end;
    background-color: $baseLight;
  }
  @include breakpoint($xlarge-up) {
    width: 1150px;
    margin: 0 auto;
    padding: 0;
  }
}
.logo {
  line-height: 0;
  flex-grow: 1;
  align-self: center;

  @include breakpoint($xlarge-up) {
    position: relative;
    left: -15px;
  }

  svg {
    position: relative;
    left: -6px;

    @include breakpoint($mainmenu-down) {
      width: 170px;
      height: 32px;
    }
  }
}
.nav-items {
  display: none;

  @include breakpoint($mainmenu-up) {
    display: flex;
    text-transform: uppercase;
  }

  .nav-item-link {
    @include font(main);
    position: relative;
    text-decoration: none;

    &.search-item {
      margin-right: -20px;
    }
  }

  .nav-item {
    margin: 43px 18px 35px 18px;
    position: relative;
    color: $fontDark;
    font-size: 15px;

    @include breakpoint($xlarge-up) {
      margin: 43px 22px 35px 22px;
    }

    &.search-btn {
      margin: 33px 22px;
    }
  }

  .search-btn {
    align-self: center;
    height: 36px;
  }

  // Hover styling state for visual feedback
  & a:hover {
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: $baseRed;
    }
  }

  .active {
    background-color: $baseRed;

    .nav-item {
      color: $baseLight;
    }
  }
}
// Drop down styling
.nav-dropdown {
  position: relative;
  display: inline-block;

  &:hover {
    background-color: $baseRed;

    .nav-item-link .nav-item {
      color: $baseLight;
    }

    .nav-dropdown-content {
      display: block;
    }
  }

  &.active {
    background-color: $baseRed;

    .nav-item {
      color: $baseLight;
    }
  }
}
.nav-dropdown-content {
  display: none;
  margin-top: 1px;
  padding: 15px 0;
  position: absolute;
  background-color: $baseRed;
  width: auto;
  min-width: 100%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 3;
  white-space: nowrap;

  a:hover {
    text-decoration: underline;
  }

}
.nav-dropdown-item {
  margin: 10px 0;
  padding: 0 22px;
  list-style: square;
  list-style-position: outside;
  padding-left: 5px;
  margin-left: 35px;
  color: white;

  & a {
    color: $baseLight;
    text-decoration: none;
  }
}
.sub-dropdown {
  &:hover {
    .nav-dropdown-sub-content {
      display: block;
    }
  }
}
.nav-dropdown-sub-content {
  display: none;
  margin-top: -49px;
  padding: 15px 22px;
  position: absolute;
  left: 100%;
  background-color: $baseGrey2;
  box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.2);
  z-index: 1;
  white-space: normal;
  width: 280px;
}
.nav-dropdown-sub-item {
  margin-top: 10px;
  list-style: square;
  list-style-position: outside;
  color: $fontDark;
  padding-left: 5px;
  margin-left: 15px;

  &.disabled {
    display: none;
  }

  & a {
    color: $fontDark;
    text-decoration: none;

    &:hover {
      &::after {
        display: none;
      }
    }
  }
}
.item-arrow-right {
  position: absolute;
  right: 10px;
  background: url("../images/icons/arrow-white.png") no-repeat scroll 50% 50% transparent;
  text-indent: -999em;
  color: $baseLight;
  display: inline-block;
  width: 10px;
}
// Exceptions and specific styling

// This exception is added because the dropdown would go off screen.
#about {
  right: 0;
}
// Mobile nav
.menu-nav-toggle {
  display: block;
  align-self: center;
  cursor: pointer;

  @include breakpoint($mainmenu-up) {
    display: none;
  }
}
.mobile-search {
  margin-top: 8px;
  margin-right: 30px;
  align-self: center;

  @include breakpoint($mainmenu-up) {
    display: none;
  }
}
.toggle-bar {
  margin: 3px 0;
  width: 20px;
  height: 3px;
  background-color: $baseRed;
}
.mobile-nav {
  display: block;
  padding: 5px 0;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: $baseRed;
  z-index: 3;

  &.closed {
    display: none;
  }

  .nav-item {
    padding: 10px 20px;
    text-transform: uppercase;
  }

  .nav-dropdown-item {
    color: $fontDark;
  }

  .nav-item-link {
    width: calc(100% - 56px);
    @include font(main);
    color: $baseLight;
    text-decoration: none;
  }
}
.mobile-nav-dropdown {
  justify-content: space-between;
}
.sub-menu-toggle {
  padding: 10px;
  width: 56px;
  color: $baseLight;
  text-align: center;
  text-indent: -999em;
  cursor: pointer;
  background: url("../images/icons/arrow-white.png") no-repeat scroll 50% 50% transparent;
}

.active-nav-toggle {
  position: absolute;
  top: -60px;
  right: 0;
  display: block;
  width: 60px;
  height: 60px;
  background-color: transparent;

  &.closed {
    display: none;
  }
}

.mobile-nav-dropdown-content {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .inner-left {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(63, 63, 63, .65); //$fontDark; // opacity: 0.65;

    .nav-back {
      position: relative;
      top: 0;
      left: 0;
      width: 20vw;
      height: 20vw;
      display: block;
      background-color: $baseGrey2;
      border: medium none;

      &:before {
        content: '';
        position: absolute;
        top: calc(10vw - 7px);
        left: calc(10vw - 5px);
        width: 6px;
        height: 10px;
        background-image: url(../images/icons/arrow-red.png);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  .inner-right {
    position: absolute;
    right: 0;
    width: 80vw;
    height: auto;
    margin-top: 0;
    padding-left: 0;
    background-color: $baseGrey2;
    //box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.2);

    .nav-dropdown-item {
      padding-right: 0;

      a {
        padding: 10px 0;
        color: $fontDark;
        text-transform: uppercase;
      }
    }
  }

  &.closed {
    display: none;
  }
}
