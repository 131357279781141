// Text variations
.text-default {
  @include font(main);
  color: $fontDark;

  &.bold {
    @include font(main-bold);
  }
}

.text-light {
  @include font(main);
  color: $fontLight;

  &.bold {
    @include font(main-bold);
  }
}

.text-grey {
  @include font(main);
  color: $fontGrey;

  &.bold {
    @include font(main-bold);
  }
}

.text-lightGrey {
  @include font(main);
  color: $baseGrey3;

  &.bold {
    @include font(main-bold);
  }
}

.text-red {
  @include font(main);
  color: $baseRed;

  &.bold {
    @include font(main-bold);
  }
}

.content-inner a,
.text-default a {
  position: relative;
  color: $baseRed;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $baseRed;
  }
}