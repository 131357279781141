.main {
  width: 100%;

  @include breakpoint($large-up) {
    width: 75%;
  }
}
.sidebar {
  width: 100%;
  text-transform: uppercase;
  margin: 0 0 20px;

  @include breakpoint($large-up) {
    width: calc(25% - 20px);
    margin-right: 20px;
  }
}
.sidebar-container {
  border: 1px solid $baseRed;
  text-align: center;

  .sidebar-item {
    margin-bottom: 20px;
  }

  .sidebar-item a {
    color: #000;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;

    &::before {
      content: '';
      display: block;
      margin-bottom: 20px;
      width: 100%;
      height: 1px;
      background-color: $baseGrey;
    }
    &:hover {
      color: $baseRed;
    }
  }

  &:last-of-type {
    &::after {
      content: '';
      display: block;
      margin-top: 20px;
      width: 100%;
      height: 1px;
      background-color: $baseGrey;
    }
  }
  .active, .active a {
    color: $baseRed;
    font-family: $fontTypeBaseBold;
  }

  @include breakpoint($large-up) {
    border: none;
    text-align: left;
  }
}
.category-title {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  user-select: none;

  @include breakpoint($large-up) {
    margin-bottom: 2em;
    padding: 0;
    text-align: left;
    cursor: default;
    user-select: text;
  }
}
.sub-menu {
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: $baseGrey2;
  }

  &.disabled {
    .submenu-header {
      opacity: .4;
      pointer-events: none;
      cursor: default;
    }
    .sidebar-icon {
      background-color: #9c9c9c;
    }
  }
}
//.sidebar-content {
//  display: none;
//
//  @include breakpoint($large-up) {
//    display: block;
//  }
//}
.open {
  display: block;
}
.submenu-header {
  display: block;
  margin: 20px 0;
  cursor: pointer;
  user-select: none;
  position: relative;
  padding-left: 18px;
  text-align: left;
  transition: color .2s ease;
  color: $fontDark;
  text-decoration: none;

  &.selected {
    color: $baseRed;
  }

  &.active {
    color: $fontDark;
  }

  @include breakpoint($large-down) {
    padding: 0 15px 0 20px;
    text-align: center;

    .sidebar-icon {
      display: none;
    }
  }

  &:hover {
    color: $baseRed;
  }
}
.sidebar-icon {
  color: $baseRed;
  position: absolute;
  top: 8px;
  left: 0;
  width: 6px;
  height: 6px;
  background-color: $baseRed;

  @include breakpoint($large-down) {
    left: 10px;
  }
}
.sub-menu-list {
  margin-left: 20px;
  padding: 0;
  list-style-type: none;
}
.sub-menu-item {
  margin: 20px 0;

  a {
    text-decoration: none;
    color: $fontGrey;

    &:hover {
      color: $baseRed;
    }
  }
}
