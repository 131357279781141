// Courses page specific styling
.page-courses {
  .card-content {
    padding-top: 0;
  }

  .referrals {
    background-color: $baseGrey;
    min-height: 215px;
  }
}

.sponsors {
  .sponsor-logo {
    align-self: center;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
