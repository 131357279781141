// base styling
*,
*::before,
*::after {
  //margin: 0;
  //padding: 0;
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

html {
  //this causes you to put pixels easily in rem - 24px = 2.4rem
  font-size: 62.5%;
}
// basic font-size
body {
  font-size: 16px;
  line-height: 1.5em;
  @include font(main);
  color: $fontDark;
}

.ratio {
  background-color: #efefef !important;
  background-repeat: no-repeat !important;
  width: 100%;
  padding-top: 75%;
  position: relative;
}

.ratio.square {
  padding-top: 100%;
}

.ratio.flat {
  padding-top: 60%;
}

// used on `home > recent cases`
.ratio-custom {
  padding-top: 75%;

  @include breakpoint($large-up) {
    padding-top: 25vw;
  }

  @include breakpoint($xlarge-up) {
    padding-top: 300px;
  }
}

strong,
b {
  font-family: $fontTypeBaseBold;
  font-weight: bold;
}
