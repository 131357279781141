// Homepage specific styles

// Recent cases exception
.recent-cases {
  .recent-cases-header {
    justify-content: space-between;
  }

  // Overview cta button
  .overview-btn {
    margin-bottom: 30px;
    align-self: flex-end;
  }

  // Not a pretty fix but hey
  .card-img img {
    height: 250px;
  }

  .btn-card-bordered {
    width: 100%;

    @include breakpoint($large-down) {
      max-width: none;
    }
  }

  .card-content {
    padding: 20px;
    min-height: 150px;
  }

  .grid-item {
    @include breakpoint($large-up) {
      margin-bottom: -10px;
      padding-bottom: 0;
    }

    &.submit-case {
      margin-bottom: -10px;
      padding-bottom: 0;
    }
  }
}

// Picture of the week exceptions
.potw {
  @include breakpoint($large-up) {
    margin-top: 20px;
  }
  & img {
    max-width: 100%;
  }
}
