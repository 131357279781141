// Contact form
// Used in the footer
.contact-form {

}

fieldset {
  margin: 0;
  padding: 0;
  border: medium none;
}

textarea {
  height: 100px;
}

.contact-form-input, .contact-form-textarea {
  padding: 10px;
  width: 100%;
  border: 0;
  resize: none;
}

.form-submit {
  border: 0;
}

// Submit case form
// Used in the submit case page

.form-group {
  margin: 0 0 40px;
  width: 100%;
}

.form-label {
}

label.checkbox {
  margin: 0 1.4em .4em 0;
  clear: both;
  float: left;
}
.checkbox input {
  margin-right: .4em;
  position: relative;
  top: -2px;
}

.form-input {
  width: 100%;
  margin-top: 10px;
  padding: 13px 20px;
  border: 1px solid $baseGrey3;
}

.form-select {
  width: 100%;
  height: 3em;
  margin-top: 10px;
  padding: 13px 20px;
  background-color: white;
  border: 1px solid $baseGrey3;
  border-radius: 0;
  -webkit-appearance: none;

  &.mce_inline_error {
    border-color: #e85c41;
  }
}

div.mc_inline_error {
  float: left;
  clear: both;
  background-color: transparent;
  border: 1px solid #e85c41;
  color: #e85c41;
  margin: .4em 0 1em;
}

.content__gdprLegal {
  font-size: .75em;
}

.form-file-upload input {
    display: none;
}

.form-file-label {
  cursor: pointer;

  img {
    float: left;
    margin: -5px 10px 0 0;
  }
}

.field-description {
  clear: both;
  font-size: 0.7em;
  line-height: 1.6em;
  margin-top: 1.4em;
}

.uploadList {
  clear: both;
  padding-top: 1em;
}
.uploadItem {
  list-style: none;

  svg,
  div {
    display: inline;
  }

  svg {
    margin-right: 10px;
  }

  div {
    position: relative;
    top: -8px;
    margin-top: -10px;
  }
}
.form-file-name {
  font-weight: bold;
  margin-right: 10px;
}

.form-file-size {
  font-size: .7em;
}

.form-controls {
  justify-content: flex-start;
}

.form-cancel {
  margin-left: 20px;
}

#subscribe-message, #contact-form-message {
  margin: 20px 0;
}


::-webkit-input-placeholder {
  @include font(main-italic);
  color: $fontLight;
  transition: color .2s ease-out;
}

:-moz-placeholder { /* Firefox 18- */
  @include font(main-italic);
  color: $fontLight;
  transition: color .2s ease-out;
}

::-moz-placeholder { /* Firefox 19+ */
  @include font(main-italic);
  color: $fontLight;
  transition: color .2s ease-out;
}

:-ms-input-placeholder {
  @include font(main-italic);
  color: $fontLight;
  transition: color .2s ease-out;
}

*:focus {outline:none}

:focus::-webkit-input-placeholder {
  color: transparent;
  outline: none;
}

:focus::-moz-placeholder {
  color: transparent;
  outline: none;
}

:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px white inset;
  border-color: #dedfe1 !important;
}
