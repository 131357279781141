// Colours
$baseLight: #ffffff;
$baseRed: #942337;
$baseGrey: #eeeced;
$baseGrey2: #e4e4e4;
$baseGrey3: #979797;

$borderGrey: #cdcdcd;

// Font colours
$fontDark: #3f3f3f;
$fontGrey: #737373;
$fontLight: #b7b7b7;

