// paths
$path-to-font: '../fonts';

@font-face {
  font-family: 'MontserratRegular';
  src: url('#{$path-to-font}/Montserrat-Regular.woff2') format('woff2'),
    url('#{$path-to-font}/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratBold';
  src: url('#{$path-to-font}/Montserrat-Bold.woff2') format('woff2'),
    url('#{$path-to-font}/Montserrat-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}