// Base styling for content sections
.content {
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  max-width: $xlarge;
}

.column-medium {
  max-width: 900px;
}

.content-inner {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;

  p {
    margin-bottom: 1em;
  }
}

// Section White
.content-light {
  background-color: $baseLight;
}

.content-grey {
  background-color: $baseGrey;
  width: 100%;
}

// Divider element for content sections
.content-divider {
  &::before {
    content: '';
    margin-bottom: 20px;
    display: block;
    width: 100%;
    max-width: $xlarge;
    height: 1px;
    background-color: $baseGrey2;
  }
}

.join-form {
  padding-top: 0;
  margin-bottom: 20px;
}
