// note from Ben: used this pattern `[class*="h1-"]` as it was faster
// then to rewrite the classes in all partials

// If possible the Title module should be set up more scalable
// >> h-1, h-2, ... && h-black, h-white, ... && h-bold, h-regular etc

//========================= general
[class*="h1-"],
[class*="h2-"],
[class*="h3-"],
[class*="h4-"],
[class*="h5-"] {
  text-transform: uppercase;
  line-height: 1.2em;
}

//========================= font-family
[class*="h1-"],
[class*="h2-"],
[class*="h3-"],
[class*="h5-"] {
  @include font(main-bold);
}

[class*="h4-"] {
  @include font(main);
}

//========================= font-sizes
[class*="h1-"],
[class*="h2-"] {
  font-size: $sizeFontTitleLarger;
}

[class*="h3-"],
[class*="h4-"] {
  font-size: $sizeFontSubTitle;
}

[class*="h5-"] {
  font-size: $sizeFontSmaller;
}

//========================= colors
[class*="-white"] {
  color: $baseLight;
}

[class*="-dark"] {
  color: $fontDark;
}

// Title after line
// .title-line {
//   &::after {
//     content: '';
//     margin: 25px 0;
//     display: block;
//     width: 32px;
//     height: 6px;
//     background-color: $baseRed;
//   }
// }

.title-line {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 32px;
    height: 6px;
    background-color: $baseRed;
  }
}

//.grid-item:not(:only-child) .card-content .title-line {
  //min-height: 3em;
//}


.main h1, h2 {
  @include font(main-bold);
  font-size: $sizeFontTitleLarger;
  line-height: 130%;
}

.main h3, h4 {
  @include font(main-bold);
  font-size: $sizeFontSubTitle;
  line-height: 130%;
}
