// Visibility
.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

// Flex box
.flex {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.vertical {
  flex-direction: column;
}

.horizontal {
  flex-direction: row;
}

.between {
  justify-content: space-between;
}

// Grid with columns
.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.grid-item {
  margin: 0 10px 20px 10px;

  &.bordered {
    border: 1px solid $baseGrey2;

    .ratio {
      width: calc(100% + 42px);
      margin-top: -21px;
      margin-left: -21px;
    }
  }

  .text-default {
    margin-top: 20px;
  }

  &.has-button {
    position: relative;
    padding-bottom: 20px;

    .card-content,
    .card-content-fill {
      margin-bottom: 60px;
    }
  }
}

// Text align
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// Spacings
.m-top {
  margin-top: 20px;
}

.m-bottom {
  margin-bottom: 20px;
}

// Paddings
.p-top {
  padding-top: 15px;
}

.p-bottom {
  padding-bottom: 15px;
}

// Alignments
.centered {
  margin: 0 auto;
}
