// Main buttons
.btn {
  display: block;
  padding: 2px 30px 0 30px;
  @include font(main-bold);
  font-size: 12px;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border: 2px solid $baseRed; // Quick fix for jumping button

  @include breakpoint($large-up) {
    max-width: 260px;
  }
}

.btn-primary {
  background-color: $baseRed;
  color: $baseLight;

  &:hover {
    background-color: $baseLight;
    color: $baseRed;
    border: 2px solid $baseRed;
  }
}

.btn-secondary {
  background-color: $baseLight;
  color: $baseRed;
  border: 2px solid $baseRed;
  @include font-smoothing(true);
  letter-spacing: 0.1em;

  &:hover {
    background-color: $baseRed;
    color: $baseLight;
  }
}

// CTA buttons
.cta {
  position: relative;
  margin-left: 15px;
  @include font(main-bold);
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  &:hover::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $baseLight;
  }
}

.cta-primary {
  color: $baseRed;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: -15px;
    width: 7px;
    height: 10px;
    background-image: url("../images/icons/arrow-red.png");
  }
  &.overview-btn::before {
    top: 6px;
  }

  &:hover::after {
    height: 2px;
    background-color: $baseRed;
  }
}

.cta-primary-back {
  color: $baseRed;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 25%;
    left: -15px;
    width: 7px;
    height: 10px;
    background-image: url("../images/icons/arrow-red.png");
    transform: rotate(180deg);
  }

  &:hover::after {
    height: 2px;
    background-color: $baseRed;
  }
}

.cta-secondary {
  color: $baseLight;
  cursor: pointer;
  text-shadow: 0 2px 4px rgba(0, 0, 0, .45);
  @include font-smoothing(true);

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 25%;
    left: -15px;
    width: 7px;
    height: 10px;
    background-image: url("../images/icons/arrow-white.png");
  }

  &:hover::after {
    bottom: 0;
    height: 2px;
    background-color: $baseLight;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .45);
  }
}

.cta-secondary-back {
  position: absolute;
  top: 15px;
  display: block;
  color: $baseLight;
  cursor: pointer;
  text-shadow: 0 2px 4px rgba(0, 0, 0, .45);

  &::before {
    content: '';
    position: absolute;
    top: 8px; // 25%
    left: -15px;
    width: 7px;
    height: 10px;
    background-image: url("../images/icons/arrow-white.png");
    transform: rotate(180deg);
  }

  &:hover::after {
    background-color: $baseLight;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .45);
  }
}

// Card bordered button exception
.btn-card-bordered {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
  //max-width: none;
}

.content p,
.card-content p,
.additional-info {
  a {
    color: $baseRed;
    text-decoration: none;
  }
}
